import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(!_vm.$auth.loading && _vm.$auth.isAuthenticated)?_c(VMain,[_c(VAppBar,{attrs:{"flat":"","dense":"","id":"appBar","dark":""}},[_c(VAppBarTitle,[_vm._v("UMD Controller")]),_c(VSpacer),_c(VMenu,{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g({attrs:{"icon":"","attrs":attrs}},on),[_c(VIcon,{staticClass:"mx-2"},[_vm._v(" mdi-account-circle ")])],1),_c('div',{staticClass:"d-none d-sm-flex"},[_vm._v(_vm._s(_vm.$auth.user.name))]),_c('div',{staticClass:"d-sm-none"},[_vm._v(_vm._s(_vm.$auth.user.nickname))])]}}],null,false,2499900702)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.logout()}}},[_c(VListItemTitle,[_vm._v("Logout")]),_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-logout-variant")])],1)],1)],1)],1)],1),_c('router-view')],1):_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[_c(VProgressCircular,{attrs:{"size":"40","indeterminate":"","color":"primary"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }