<template>
<v-container>
  <div class="card mt-8">
    <v-row>
      <v-card-title class="white--text ml-3">
        UMD Devices
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon dark class="ma-3 mr-6" title="Add New Device" @click="clearEndpointBuffer(), newDeviceDialog = true">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-row>  
    <v-list-item
      dark
      v-for="(item, i) in endpoints"
      :key="i"
    >
      <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
      <div v-if="userHasPermission(item)" class="buttDiv">
        <v-btn icon title="Open Device" @click="openUmdDialog(i)" style="float: right">
          <v-icon>
            mdi-open-in-new
          </v-icon>
        </v-btn>
        <v-btn icon title="Edit User Permissions" @click="openPermissionsDialog(i)" class="mx-1" style="float: right">
          <v-icon>
            mdi-account-edit-outline
          </v-icon>
        </v-btn>
      </div>

      <div v-else class="buttDiv">
        <v-icon class="mx-1" color="red darken-3" style="float: right">
          mdi-account-lock
        </v-icon>
      </div>
    </v-list-item>
  </div>

  <v-dialog
    v-model="newDeviceDialog"
    transition="dialog-bottom-transition"
    max-width="570"
    class="dialog"
  >
    <v-card class="dialogCard1">
      <v-toolbar dark color="rgb(16, 95, 246)" flat elevation="0" dense>
          <v-toolbar-title>New Device</v-toolbar-title>
      </v-toolbar>

      <div class="pl-5 pr-5 pt-5">
        <v-text-field
          dark
          label="Serial"
          outlined
          :rules="[rules.required, rules.counter]"
          v-model="endpointBuffer.serial"
        ></v-text-field>

        <v-text-field
          dark
          label="Friendly Name"
          outlined
          :rules="[rules.required]"
          v-model="endpointBuffer.friendlyName"
        ></v-text-field>
      </div> 

      <v-card-actions>
        <v-spacer>

        </v-spacer>
        <v-btn
          dark
          text
          @click="newDeviceDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          dark
          text
          @click="createNewDevice()"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog
    v-model="permissionsDialog"
    transition="dialog-bottom-transition"
    max-width="570"
    class="dialog"
  >
    <v-card class="dialogCard1">
      <v-toolbar dark color="rgb(16, 95, 246)" flat elevation="0" dense>
          <v-toolbar-title>Device Permissions</v-toolbar-title>
      </v-toolbar>

      <div v-if="!loadingUserlist">
        <div v-for="(user, index) in userList" :key="index" class="d-flex mx-2" style="color: white;">
          <v-checkbox :input-value="!isBlacklisted(user.email)" v-on:change="handleCheckbox($event, user.email)" dark class="mx-2"></v-checkbox>
          <v-list-item-title>{{user.name}}</v-list-item-title>
        </div>
      </div>

      <div v-else class="d-flex justify-center mt-10 mb-5">
        <v-progress-circular size="40" indeterminate color="primary">
        </v-progress-circular>
      </div>

      <v-card-actions>
        <v-spacer>

        </v-spacer>
        <v-btn
          dark
          text
          @click="permissionsDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          dark
          text
          @click="saveDevicePermissions()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  

  <v-dialog
      v-model="controlDeviceDialog"
      transition="dialog-bottom-transition"
      max-width="730"
      style="overflow-y: scroll;"
    >
    <v-card class="dialogCard pb-3">
      <v-toolbar dark color="rgb(16, 95, 246)" flat elevation="0" dense>
            <v-toolbar-title v-if="!this.editDeviceName">{{ this.endpointBuffer.friendlyName }}</v-toolbar-title>
            <input type="text" id="nameInput" v-model="endpointBuffer.friendlyName" v-else/>
            <v-btn
              dark
              @click="editDeviceName = true"
              class="ml-1"
              icon
              small
              title="Edit Name"
              v-if="!this.editDeviceName"
              >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              dark
              @click="editDeviceName = false, saveDeviceName()"
              class="ml-2"
              icon
              small
              title="Save"
              v-else
              >
              <v-icon small>mdi-content-save-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              dark
              text
              @click="deleteDevice(endpointBuffer.serial), controlDeviceDialog = false"
              class=""
              color="red lighten-1"
            >
              Delete
            </v-btn>
            
            <v-btn
              dark
              text
              @click="getInitialData(), controlDeviceDialog = false"
              class="mr-n2"
            >
              Close
            </v-btn>
      </v-toolbar>

      <div class="channelParent">
        <div class="channelDiv">
          <div class="modeDiv">
            <v-btn x-small icon dark class="ma-1" :input-value="endpointBuffer.channels[0].mode == 0" @click="modeSelect(0, 0)" title="Text">
              <v-icon>
                mdi-format-text
              </v-icon>
            </v-btn>
            <v-btn x-small icon dark class="ma-1" :input-value="endpointBuffer.channels[0].mode == 1" @click="modeSelect(0, 1)" title="Time">
              <v-icon>
                mdi-clock-outline
              </v-icon>
            </v-btn>
            <v-btn x-small icon dark class="ma-1" :input-value="endpointBuffer.channels[0].mode == 2" @click="modeSelect(0, 2)" title="Date">
              <v-icon>
                mdi-calendar-month
              </v-icon>
            </v-btn>
              <v-menu
                open-on-hover
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small icon dark class="ma-1" title="Special Func" v-bind="attrs" v-on="on" :input-value="endpointBuffer.channels[0].mode == 3">
                  <v-icon>
                    mdi-dots-horizontal-circle-outline
                  </v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-for="(item, index) in specialFuncs"
                  :key="index"
                  @click="specialFuncSelect(0, item.k, item.v)"
                >
                  <v-list-item-title>{{ item.k }}</v-list-item-title>
                </v-list-item>
              </v-list>
              </v-menu>
          </div>


          <div class="textMod">
            <input type="text" class="no-outline umdInput" :maxlength="lengthDecider(endpointBuffer.channels[0].font)" :disabled="endpointBuffer.channels[0].mode != 0" v-model="endpointBuffer.channels[0].text" v-bind:class="{ greenInput:(endpointBuffer.channels[0].color == 0), redInput:(endpointBuffer.channels[0].color == 1), orangeInput:(endpointBuffer.channels[0].color == 2), smaller:(endpointBuffer.channels[0].font == 1), clockMode:(endpointBuffer.channels[0].mode != 0) }"/>
            <div class="colorSwatch green" v-bind:class="{activeSwatch: (endpointBuffer.channels[0].color == 0)}" @click="colorSelect(0,0)"></div>
            <div class="colorSwatch red" v-bind:class="{activeSwatch: (endpointBuffer.channels[0].color == 1)}" @click="colorSelect(0,1)"></div>
            <div class="colorSwatch orange" v-bind:class="{activeSwatch: (endpointBuffer.channels[0].color == 2)}" @click="colorSelect(0,2)"></div>

            <div class="fontSwatch" v-bind:class="{activeFontSwatch: (endpointBuffer.channels[0].font == 0)}" @click="fontSelect(0,0)" title="Normal Font">
              <p class="fontLabel">Norm</p>
            </div>
            <div class="fontSwatch" v-bind:class="{activeFontSwatch: (endpointBuffer.channels[0].font == 1)}" @click="fontSelect(0,1)" title="Small Font">
              <p class="fontLabel">Small</p>
            </div>
            <div class="fontSwatch" v-bind:class="{activeFontSwatch: (endpointBuffer.channels[0].font == 2)}" @click="fontSelect(0,2)" title="Bold Font">
              <p class="fontLabel">Bold</p>
            </div>
            <div class="fontSwatch" v-bind:class="{activeFontSwatch: (endpointBuffer.channels[0].font == 3)}" @click="fontSelect(0,3)" title="Inverted Font">
              <p class="fontLabel">Inv</p>
            </div>
          </div>
        </div>

        <div class="channelDiv">
          <div class="modeDiv">
            <v-btn x-small icon dark class="ma-1" :input-value="endpointBuffer.channels[1].mode == 0" @click="modeSelect(1, 0)" title="Text">
              <v-icon>
                mdi-format-text
              </v-icon>
            </v-btn>
            <v-btn x-small icon dark class="ma-1" :input-value="endpointBuffer.channels[1].mode == 1" @click="modeSelect(1, 1)" title="Time">
              <v-icon>
                mdi-clock-outline
              </v-icon>
            </v-btn>
            <v-btn x-small icon dark class="ma-1" :input-value="endpointBuffer.channels[1].mode == 2" @click="modeSelect(1, 2)" title="Date">
              <v-icon>
                mdi-calendar-month
              </v-icon>
            </v-btn>
            <v-menu
                open-on-hover
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small icon dark class="ma-1" title="Special Func" v-bind="attrs" v-on="on" :input-value="endpointBuffer.channels[1].mode == 3">
                  <v-icon>
                    mdi-dots-horizontal-circle-outline
                  </v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-for="(item, index) in specialFuncs"
                  :key="index"
                  @click="specialFuncSelect(1, item.k, item.v)"
                >
                  <v-list-item-title>{{ item.k }}</v-list-item-title>
                </v-list-item>
              </v-list>
              </v-menu>
          </div>


          <div class="textMod">
            <input type="text" class="no-outline umdInput" :maxlength="lengthDecider(endpointBuffer.channels[1].font)" :disabled="endpointBuffer.channels[1].mode != 0" v-model="endpointBuffer.channels[1].text" v-bind:class="{ greenInput:(endpointBuffer.channels[1].color == 0), redInput:(endpointBuffer.channels[1].color == 1), orangeInput:(endpointBuffer.channels[1].color == 2), smaller:(endpointBuffer.channels[1].font == 1), clockMode:(endpointBuffer.channels[1].mode != 0) }"/>
            <div class="colorSwatch green" v-bind:class="{activeSwatch: (endpointBuffer.channels[1].color == 0)}" @click="colorSelect(1,0)"></div>
            <div class="colorSwatch red" v-bind:class="{activeSwatch: (endpointBuffer.channels[1].color == 1)}" @click="colorSelect(1,1)"></div>
            <div class="colorSwatch orange" v-bind:class="{activeSwatch: (endpointBuffer.channels[1].color == 2)}" @click="colorSelect(1,2)"></div>

            <div class="fontSwatch" v-bind:class="{activeFontSwatch: (endpointBuffer.channels[1].font == 0)}" @click="fontSelect(1,0)" title="Normal Font">
              <p class="fontLabel">Norm</p>
            </div>
            <div class="fontSwatch" v-bind:class="{activeFontSwatch: (endpointBuffer.channels[1].font == 1)}" @click="fontSelect(1,1)" title="Small Font">
              <p class="fontLabel">Small</p>
            </div>
            <div class="fontSwatch" v-bind:class="{activeFontSwatch: (endpointBuffer.channels[1].font == 2)}" @click="fontSelect(1,2)" title="Bold Font">
              <p class="fontLabel">Bold</p>
            </div>
            <div class="fontSwatch" v-bind:class="{activeFontSwatch: (endpointBuffer.channels[1].font == 3)}" @click="fontSelect(1,3)" title="Inverted Font">
              <p class="fontLabel">Inv</p>
            </div>
          </div>
        </div>

        <v-btn icon dark class="mt-6 mx-5 d-none d-sm-inline" title="Send to UMD" @click="sendData(endpointBuffer)">
          <v-icon>
            mdi-send
          </v-icon>
        </v-btn>

        <div class="d-flex justify-center d-sm-none">
          <v-btn color="blue" class="mt-4 ml-2 mr-2" title="Send to UMD" @click="sendData(endpointBuffer)">
            SEND
            <v-icon class="ml-2">
              mdi-send
            </v-icon>
          </v-btn>
        </div>
      </div>

      <p class="ml-3 mb-n2 grey--text text-caption">Serial: {{ this.endpointBuffer.serial }}</p>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import axios from "axios";
  export default {
    name: 'Home',
    methods: {
      clearEndpointBuffer: function() {
        this.endpointBuffer = {
          serial: "",
          friendlyName: "",
          mode: 0,
          blackList: [],
          channels: [
            {
              mode: 0,
              text: "",
              color: 0,
              font: 0
            },
            {
              mode: 0,
              text: "",
              color: 0,
              font: 0
            },
          ]
        }
        this.editDeviceName = false;
      },
      sanitizeText: function(text) {
        let unwantedStrings = [
          "%85C",
          "%255C",
          "%2A",
          "%4A",
          "%16A",
          "$t",
          "$d"
        ]

        let sanitizedText = text;
        for (let i = 0; i < unwantedStrings.length; i++) {
          let regex = new RegExp(unwantedStrings[i], 'g');
          sanitizedText = sanitizedText.replace(regex, "");
        }

        return sanitizedText;
      },
      colorSelect: function(chan, col) {
        this.endpointBuffer.channels[chan].color = col;
      },
      lengthDecider: function(font) {
        if (font == 1) {
          return 10
        } else {
          return 8
        }
      },
      fontSelect: function(chan, font) {
        this.endpointBuffer.channels[chan].font = font;
      },
      modeSelect: function(chan, mode) {
        this.endpointBuffer.channels[chan].mode = mode;
        if (mode == 1) {
          this.endpointBuffer.channels[chan].text = "--:--:--";
        } else if (mode == 2) {
          this.endpointBuffer.channels[chan].text = "-/-/-";
        } else if (mode == 3) {
          this.endpointBuffer.channels[chan].text = "example";
        } else {
          this.endpointBuffer.channels[chan].text = "";
        }
      },
      specialFuncSelect: function(chan, key, val) {
        this.endpointBuffer.channels[chan].mode = 3;
        this.endpointBuffer.channels[chan].text = val
        this.endpointBuffer.channels[chan].funcKey = key
      },
      alphabeticSort: function(array) {
        return array.sort(function(a, b) {
          var textA = a.k.toUpperCase();
          var textB = b.k.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      },
      endpointSort: function(array) {
        return array.sort(function(a, b) {
          var textA = a.friendlyName.toUpperCase();
          var textB = b.friendlyName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      },
      openUmdDialog(index) {
        this.endpointBuffer.serial = this.endpoints[index].serial;
        this.endpointBuffer.friendlyName = this.endpoints[index].friendlyName;
        this.endpointBuffer.mode = this.endpoints[index].mode;
        this.endpointBuffer.channels[0].mode = this.endpoints[index].channels[0].mode;
        this.endpointBuffer.channels[0].text = this.sanitizeText(this.endpoints[index].channels[0].text);
        this.endpointBuffer.channels[0].color = this.endpoints[index].channels[0].color;
        this.endpointBuffer.channels[0].font = this.endpoints[index].channels[0].font;
        this.endpointBuffer.channels[0].funcKey = this.endpoints[index].channels[0].funcKey;

        this.endpointBuffer.channels[1].mode = this.endpoints[index].channels[1].mode;
        this.endpointBuffer.channels[1].text = this.sanitizeText(this.endpoints[index].channels[1].text);
        this.endpointBuffer.channels[1].color = this.endpoints[index].channels[1].color;
        this.endpointBuffer.channels[1].font = this.endpoints[index].channels[1].font;
        this.endpointBuffer.channels[1].funcKey = this.endpoints[index].channels[1].funcKey;

        this.getSpecialFuncs();

        this.editDeviceName = false;
        this.controlDeviceDialog = true;
      },
      openPermissionsDialog(index) {
        this.getUserList();
        this.endpointBuffer.serial = this.endpoints[index].serial;
        this.endpointBuffer.friendlyName = this.endpoints[index].friendlyName;
        this.endpointBuffer.blackList = this.endpoints[index].blackList || [];
        this.permissionsDialog = true;
      },
      getInitialData: async function() {
        let token = await this.$auth.getTokenSilently();
        const options = {
            headers: {
                "Authorization": ("Bearer " + token),
            },
        };
        axios
        .get("/endpointsregister", options)
        .then((response) => {
          //console.log(response)
          if (response.data != null) {
            if (response.data.length > 0) {
              this.endpoints = this.endpointSort(response.data);
            } else {
              this.endpoints = []
            }
          } else {
            this.endpoints = []
          }
        })
        .catch((error) => console.log(error));
      },
      getSpecialFuncs: async function() {
        let token = await this.$auth.getTokenSilently();
        const options = {
            headers: {
                "Authorization": ("Bearer " + token),
            },
        };
        axios
        .get("/specialfuncs", options)
        .then((response) => {
          //console.log(response)
          if (response.data != null) {
            if (response.data.length > 0) {
              this.specialFuncs = this.alphabeticSort(response.data);
            } else {
              this.specialFuncs = []
            }
          } else {
            this.specialFuncs = []
          }
        })
      },
      getUserList: async function() {
        this.loadingUserlist = true;
        let token = await this.$auth.getTokenSilently();
        const options = {
            headers: {
                "Authorization": ("Bearer " + token),
            },
        };
        axios
        .get("/userlist", options)
        .then((response) => {
          //console.log(response)
          if (response.data != null) {
            if ('name' in response.data[0]) {
              this.userList = response.data;
              this.loadingUserlist = false;
            } else {
              this.userList = [];
              this.loadingUserlist = false;
            }
          }
        })
      },
      createNewDevice: async function() {
        if (this.endpointBuffer.friendlyName.length > 0 && this.endpointBuffer.serial.length == 5) {
          let token = await this.$auth.getTokenSilently();
          const options = {
              headers: {
                  "Authorization": ("Bearer " + token),
              },
          };
          axios
          .put("/endpoint", this.endpointBuffer, options)
          .then((response) => {
          if (response.status == 200) {
            this.getInitialData();
          }
        })
          .catch((error) => console.log(error));
          this.newDeviceDialog = false;
        }
      },
      saveDeviceName: function() {
        if (this.endpointBuffer.friendlyName.length > 0) {
          this.saveEndpoint();
          this.editDeviceName = false;
        }
      },
      saveDevicePermissions: function() {
        this.saveEndpoint();
        this.permissionsDialog = false;
      },
      saveEndpoint: async function() {
        //Saves endpoint currently in endpointBuffer
        let token = await this.$auth.getTokenSilently();
          const options = {
              headers: {
                  "Authorization": ("Bearer " + token),
              },
          };
          axios
          .put("/endpoint", this.endpointBuffer, options)
          .then((response) => {
          if (response.status == 200) {
            this.getInitialData();
          }
        })
        .catch((error) => console.log(error));
      },
      deleteDevice: async function(serial) {
        let token = await this.$auth.getTokenSilently();
        const options = {
            headers: {
                "Authorization": ("Bearer " + token),
            },
            data: {
              serial: serial,
            }
        };
        axios
        .delete("/endpoint", options)
        .then((response) => {
          if (response.status == 200) {
            this.getInitialData();
          }
        })
        .catch((error) => console.log(error));
      },
      sendData: async function(payload) {
        let token = await this.$auth.getTokenSilently();
        const options = {
            headers: {
                "Authorization": ("Bearer " + token),
            },
        };
        axios
        .put("/data", payload, options)
        .catch((error) => console.log(error));
        this.getInitialData();
        this.getSpecialFuncs();
      },
      userHasPermission(endpoint) {
        let blacklist = endpoint.blackList || [];
        if (blacklist.includes(this.$auth.user.email)) {
          return false;
        } else {
          return true;
        }
      },
      isBlacklisted(email) {
        if (this.endpointBuffer.blackList.includes(email)) {
          return true;
        } else {
          return false;
        }
      },
      handleCheckbox(value, email) {
        if (value) {
          this.endpointBuffer.blackList.splice(this.endpointBuffer.blackList.indexOf(email), 1);
        } else {
          this.endpointBuffer.blackList.push(email);
        }
      }
    },
    mounted() {
      this.getInitialData();
    },  
    data: function() {
      return {
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 5 || 'Max 5 characters',
        },
        controlDeviceDialog: false,
        editDeviceName: false,
        newDeviceDialog: false,
        permissionsDialog: false,
        endpointBuffer: {
          serial: "",
          friendlyName: "",
          mode: 0,
          blackList: [],
          channels: [
            {
              mode: 0,
              text: "",
              color: 0,
              font: 0
            },
            {
              mode: 0,
              text: "",
              color: 0,
              font: 0
            },
          ]
        },
        endpoints: [],
        specialFuncs: [],
        userList: [],
        loadingUserlist: false
      }
    }
  }
</script>

<style scoped>
.textMod {
  height: 60px;
  width: 230px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  margin-right: 10px;
}

.modeDiv {
  height: 65px;
  width: 70px;
  text-align: right;
  margin-right: 3px;
  margin-top: 3px;
}

.colorSwatch {
  height: 10px;
  width: 20px;
  position: relative;
  left: 15px;
  top: -7px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: inline-flex;
  cursor: pointer;
}

.fontSwatch {
  height: 10px;
  width: 23px;
  position: relative;
  left: 35px;
  top: -9px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: inline-flex;
  cursor: pointer;
  border-bottom-color: white;
  border-left-color: white;
  border-right-color: white;
  border-style: solid;
  border-width: 1px;
  border-top-width: 0px;
  justify-content: center;
}

.activeFontSwatch {
  height: 15px !important;
}

.fontLabel {
  font-size: 7px;
  color: white;
}

.activeSwatch {
  height: 15px !important;
  top: -2px !important;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}

.greenInput {
  color: rgb(66, 200, 66);
}

.redInput {
  color: rgb(187, 73, 73);
}

.orangeInput {
  color: rgb(222, 176, 90);
}

.dialogCard {
  background-color: rgb(42, 46, 69);
}

.dialogCard1 {
  background-color: rgb(42, 46, 69);
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
}

.umdInput {
  font-size: 50px;
  height: 100%;
  line-height: 100%;
  width: 93%;
  margin-left: 5px;
  margin-right: 5px;
  font-family: dotMatrix;
}

.smaller {
  font-size: 40px;
}

.clockMode {
  margin-left: 23px;
}

.noClick {
  pointer-events: none;
}

.no-outline:focus {
  outline: none;
}

#nameInput {
  color: white;
  background-color: rgb(14, 78, 197);
  border-radius: 3px;
  padding-left: 3px;
}

@media only screen and (max-width: 600px) {
  #nameInput {
    width: 100px;
  }
}

@font-face {
  font-family: dotMatrix;
  src: url(../assets/DOTMATRI.TTF);
}

.buttDiv {
  width: 130px;
}

.channelDiv {
  display: inline-flex;
  margin-bottom: 30px;
  width: 300px;
}

.channelParent {
  flex-wrap: wrap;
  padding: 15px;
  margin-top: 10px;
  width: 100%;
}
</style>
