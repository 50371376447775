<template>
  <v-app>
    <v-main v-if="!$auth.loading && $auth.isAuthenticated">
      <v-app-bar flat dense id="appBar" dark>
        <v-app-bar-title>UMD Controller</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-menu
          open-on-hover
          top
          offset-y>
        <template v-slot:activator="{ on, attrs }">
          
          <v-btn icon v-on="on" :attrs="attrs">
            <v-icon class="mx-2">
              mdi-account-circle
            </v-icon>
          </v-btn>
          <div class="d-none d-sm-flex">{{ $auth.user.name }}</div>
          <div class="d-sm-none">{{ $auth.user.nickname }}</div>
        </template>
        <v-list dense>
              <v-list-item @click="logout()">
                <v-list-item-title>Logout</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
      </v-app-bar>
      <router-view/>
    </v-main>
    <v-container v-else fill-height fluid>
        <v-row align="center" justify="center">
          <v-col cols="1" class="d-flex justify-center">
            <v-progress-circular size="40" indeterminate color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    userName: "Jack Woodgate"
  }),
  methods: {
    logout: function () {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style>
.body,
main,
app {
  background-color: rgb(24, 26, 35);
  position: fixed;
  height: 100%;
  width: 100%;
}

.card {
  margin-left: 20%;
  margin-right: 20%;
  background-color: rgb(42, 46, 69);
  border-radius: 10px;
}

@media only screen and (max-width: 700px) {
  .card {
    margin-left: 3%;
    margin-right: 3%;
  }
}

#appBar {
  background-color: rgb(16, 95, 246);
}
</style>
